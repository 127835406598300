import { Navigate, Outlet, PathRouteProps, useLocation, useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../../state/store';
import { selectAccount, selectIsAuthenticated } from '../../../state/local/auth/auth-slice';

interface IOwnProps extends PathRouteProps {
  hasAnyAuthorities?: string[];
}

export const PrivateRoute = ({ hasAnyAuthorities = [], ...rest }: IOwnProps) => {
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const sessionHasBeenFetched = useAppSelector(state => state.auth.sessionHasBeenFetched);
  const account = useAppSelector(selectAccount);
  const isAuthorized = hasAnyAuthority(account.authorities, hasAnyAuthorities);
  const location = useLocation();

  if (!sessionHasBeenFetched) {
    return <div></div>;
  }

  if (isAuthenticated) {
    if (isAuthorized) {
      return (
          <Outlet />
      );
    }

    return (
      <div className="insufficient-authority">
        <div className="alert alert-danger">
         You are not authorized to access this page.
        </div>
      </div>
    );
  }

  return (
    <Navigate
      to={{
        pathname: '/login',
        search: location.search,
      }}
      replace
      state={{ from: location }}
    />
  );
};

export const hasAnyAuthority = (authorities: string[], hasAnyAuthorities: string[]) => {
  if (authorities && authorities.length !== 0) {
    if (hasAnyAuthorities.length === 0) {
      return true;
    }
    return hasAnyAuthorities.some(auth => authorities.includes(auth));
  }
  return false;
};

/**
 * Checks authentication before showing the children and redirects to the
 * login page if the user is not authenticated.
 * If hasAnyAuthorities is provided the authorization status is also
 * checked and an error message is shown if the user is not authorized.
 */
export default PrivateRoute;
