import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { IoTime } from "react-icons/io5";
import { BsEar, BsFileEarmarkText, BsPen, BsSoundwave } from "react-icons/bs";
import styles from "./practicePublic.module.scss";
import { useNavigate } from "react-router-dom";
import AppButton from "../../shared/elements/app-button/app-button";

interface PracticeCardProps {
  icon: React.ComponentType<{ size: number; color: string }>;
  color: string;
  title: string;
  descriptionTitle: string;
  description: string;
  completeTime: string;
  type: "practiceBtn" | "comingSoonLbl";
}

const PracticeCard: React.FC<PracticeCardProps> = ({
  icon: Icon,
  color,
  title,
  descriptionTitle,
  description,
  completeTime,
  type,
}) => {
  const navigate = useNavigate();

  const onPracticeClick = () => {
    navigate("/app/practice");
  };


  return (
    <div className={`${styles.cards} mb-3 px-4 py-2 `}>
      <Row className="pt-2 ">
        <Col className="col-12">
          <div className="d-flex align-items-center">
            <div className="d-flex gap-2">
              <div
                className={`${styles.cards__icons} d-flex align-items-center`}
              >
                <Icon size={32} color={color} />
              </div>
              <p className={`${styles.cards__title} `}>{title}</p>
            </div>
            <div className={`${styles.cards__timeIcon} d-flex gap-1 ms-auto`}>
              <div>
                <IoTime size={22} color="var(--text-secondary-color)" />
              </div>
              <p className={`${styles.cards__time} `}>{completeTime}</p>
            </div>
          </div>
          <h6 className={`${styles.cards__desTitle} mt-3`}>
            {descriptionTitle}
          </h6>
          <p className={`${styles.cards__des} mt-3`}>{description}</p>
        </Col>
      </Row>
      {type === "practiceBtn" && (
        <Col className="col-12 d-flex justify-content-start pb-4">
          <AppButton
            className={`${styles.cards__practiceBtn} `}
            text="Practice"
            btnType="filled"
            btnSize="md"
            onClick={onPracticeClick}
          />
        </Col>
      )}

      {type === "comingSoonLbl" && (
        <Col className="col-12 d-flex justify-content-start pb-2">
          <p className={`${styles.cards__comingSoonLabel}`}>Coming Soon</p>
        </Col>
      )}
    </div>
  );
};

const PracticePublicPage: React.FC = () => {
  const cardsData: PracticeCardProps[] = [
    {
      icon: BsPen,
      color: "#65E280",
      title: "Writing",
      type: "practiceBtn",
      completeTime: "60 minutes",
      descriptionTitle:
        "The Academic Writing question types in IELTS on computer are the same as in the IELTS on paper test.",
      description:
        "In Part 1, you are presented with a graph, table, chart or diagram and are asked to describe, summarise or explain the information in your own words. You may be asked to describe and explain data, describe the stages of a process, how something works or describe an object or event.In Part 2, you are asked to write an essay in response to a point of view, argument or problem.",
    },
    {
      icon: BsFileEarmarkText,
      color: "#944FDA",
      title: "Reading",
      type: "comingSoonLbl",
      completeTime: "60 minutes",
      descriptionTitle: " ",
      description:
        "The Academic Reading question types in IELTS on computer are the same as in the IELTS on paper test. A variety of tasks is used including: multiple choice, identifying information (True/False/Not Given), identifying a writer’s views/claims (Yes/No/Not Given), matching information, matching headings, matching features, matching sentence endings, summary completion, note completion, table completion, flow-chart completion, diagram label completion, short-answer questions.",
    },
    {
      icon: BsEar,
      color: "#DACC4F",
      title: "Listening",
      type: "comingSoonLbl",
      completeTime: "30 minutes",
      descriptionTitle:
        "The Listening question types for IELTS on computer are the same as in the IELTS on paper test.",
      description:
        "A variety of tasks is used including: multiple choice, matching, plan/map/diagram labelling,A variety of tasks is used including: multiple choice, matching, plan/map/diagram labelling, form completion, note completion, table completion, flow-chart completion, summary completion, sentence completion, short-answer questions.",
    },
    {
      icon: BsSoundwave,
      color: "#4FDAD2",
      title: "Speaking",
      type: "comingSoonLbl",
      completeTime: "11-14 minutes",
      descriptionTitle:
        "In the Speaking test, you have a discussion with a certified examiner. It is interactive and as close to a real-life situation as a test can get.",
      description:
        "There are three parts to the test and each part fulfils a specific function in terms of interaction pattern, task input and test taker output. In Part 1, you answer questions about yourself and your family. In Part 2, you speak about a topic. In Part 3, you have a longer discussion on the topic.",
    },
  ];

  return (
    <div>
      <section
        className={`${styles.heading} d-flex align-items-center justify-content-center mb-5 `}
      >
        <Row className="pt-3">
          <div
            className={`${styles.heading__line1} d-flex justify-content-center`}
          >
            <h1>Practice</h1>
          </div>
          <div
            className={`${styles.heading__line2} d-flex justify-content-center`}
          >
            <p>Your Gateway to IELTS Success</p>
          </div>
        </Row>
      </section>
      <section className="mt-4 mx-4">
        <Container className="mb-5 pb-5">
          <Row xl={2} lg={1} md={1} sm={1}>
            <Col>
              <PracticeCard {...cardsData[0]} />
              <PracticeCard {...cardsData[2]} />
            </Col>
            <Col>
              <PracticeCard {...cardsData[1]} />
              <PracticeCard {...cardsData[3]} />
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default PracticePublicPage;
