import { BsList, BsCheck, BsStars } from "react-icons/bs";
import { Col, Row } from "react-bootstrap";
import { IoTime } from "react-icons/io5";
import styles from "./writingPart1-page.module.scss";
import { useEffect, useRef, useState } from "react";
import {
  FaArrowRotateLeft,
  FaArrowRotateRight,
  FaArrowTrendUp,
} from "react-icons/fa6";
import CountdownTimer from "./timer";
import AppButtonIcon from "../../../../shared/elements/app-button/app-buttonIcon";
import { useGetAllSkeletonsQuery } from "../../../../state/server/sampleWriting/skeleton-api";
import AppButton from "../../../../shared/elements/app-button/app-button";
import SubmitPopup from "./submitPopup";
import OkPopup from "./okPopup";
import { useCreateAnswerMutation } from "../../../../state/server/sampleWriting/answer-api";
import AnswerPopup from "./answerPopup";

const WritingPart1: React.FC = () => {
  const feedbackRef = useRef<HTMLDivElement>(null);
  const wordLimit = 50;
  const [createAnswer] = useCreateAnswerMutation();

  const [showSubmitPopup, setShowSubmitPopup] = useState(false);
  const [showFooter, setShowFooter] = useState(false);
  const [text, setText] = useState("");
  const [selectedSkeleton] = useState<string | null>(null);
  const [showOkPopup, setShowOkPopup] = useState(false);
  const [showAnswerPopup, setShowAnswerPopup] = useState(false);
  const { data: skeletons, isLoading: isSkeletonsLoading } =
    useGetAllSkeletonsQuery();
  const [isSubmitClicked, setIsSubmitClicked] = useState(false);
  const [isTextAreaDisabled, setIsTextAreaDisabled] = useState(false);

  useEffect(() => {
    if (showFooter && feedbackRef.current) {
      feedbackRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [showFooter]);

  if (isSkeletonsLoading) {
    return <div>Loading...</div>;
  }

  const handleTextChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newText = event.target.value;
    if (calculateWordCount(newText) <= wordLimit) {
      setText(newText);
    }
  };

  const handleGenerateSkeletonClick = () => {
    setShowOkPopup(true);
  };

  const handleOkButtonClick = () => {
    setShowOkPopup(false);
    if (skeletons) {
      const skeletonText = skeletons
        .map((skeleton) => skeleton.skeletons)
        .join("\n");
      setText(skeletonText);
    }
  };

  const handleSubmitClick = () => {
    if (text.trim() === "") {
      setShowAnswerPopup(true);
    } else {
      setShowSubmitPopup(true);
    }
  };

  const handlePopupSubmit = async () => {
    const userId = "f3fd31b1-9ad7-4605-b322-9bc13998e30c";
    const chaptorId = 1;
    const answerId = 1;

    await createAnswer({
      userId,
      chaptorId,
      answerId,
      answer: text,
      generatedAns1: "",
      generatedAns2: "",
    });

    setShowFooter(true);
    setShowSubmitPopup(false);
    setIsSubmitClicked(true);
    setIsTextAreaDisabled(true);
    feedbackRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const calculateWordCount = (inputText: string): number => {
    const words = inputText.split(/\s+/).filter((word) => word !== "");
    return words.length;
  };

  const wordCount = calculateWordCount(text);

  return (
    <div>
      <section>
        <div className="d-flex align-items-center m-4">
          <div>
            <BsList size={40} color="var(--accent-color)" />
          </div>

          <p
            style={{
              color: "var(--text-primary-color)",
              fontWeight: "600",
              fontSize: "16px",
            }}
            className="mt-3 mx-2"
          >
            Sample Writing Practice
          </p>
        </div>
      </section>

      <section className={`${styles.secPart1} px-5 py-3 mx-4`}>
        <Row className="align-items-center">
          <Col className="col-8">
            <Row>
              <h5 className={`${styles.secPart1__title}`}>Part 1</h5>
            </Row>
            <Row>
              <div className={`${styles.secPart1__skeletons}`}></div>
              <h5 className={`${styles.secPart1__description}`}>
                You should spend about 20 minutes on this task. Write at least
                150 words
              </h5>
            </Row>
          </Col>
          <Col className="col-12 col-md-4 d-flex justify-content-start justify-content-md-end">
            <div
              className={`${styles.secPart1__timeIcon} d-flex gap-1  align-items-center justify-content-center`}
              style={{ visibility: isSubmitClicked ? "hidden" : "visible" }}
            >
              <div>
                <IoTime size={24} color="var(--text-secondary-color)" />
              </div>
              <div className={`${styles.secPart1__remaining} `}>Remaining:</div>
              <div className={`${styles.secPart1__minutes} `}>
                <CountdownTimer initialSeconds={300} />
              </div>
            </div>
          </Col>
        </Row>
      </section>

      <section className={`${styles.secQuestion} px-5 py-3 mx-4 mt-4`}>
        {skeletons &&
          skeletons.map((skeleton) => (
            <Row>
              <Col>
                <Row className={`${styles.secQuestion__question}`}>
                  <p>
                    The chart below shows the number of adults participating in
                    different major sports in one area, in 1997 and 2017.
                  </p>
                  <p>
                    Summaries the information by selecting and reporting the
                    main features, and make comparisons where relevant.
                  </p>
                </Row>
                <Row>
                  <img src="/chart.png" alt="An illustration of chart" />
                </Row>
              </Col>

              <Col className="col-12 col-xl-7">
                <div>
                  <div
                    className={`${styles.secQuestion__txtMain} d-flex gap-1 align-items-center p-2`}
                  >
                    <div>
                      <AppButtonIcon
                        icon={<BsStars size={18} color="var(--accent-color)" />}
                        text="Generate Skeleton"
                        onClick={() => handleGenerateSkeletonClick()}
                        style={{
                          color: "var(--text-primary-color)",
                          fontSize: "14px",
                          fontStyle: "normal",
                          fontWeight: "700",
                          width: "170px",
                          height: "35px",
                          padding: "10px",
                          borderRadius: "38px",
                          background: "var(--line-color)",
                        }}
                      />
                    </div>
                    <OkPopup
                      show={showOkPopup}
                      onHide={() => setShowOkPopup(false)}
                      onOk={handleOkButtonClick}
                      skeletonText={selectedSkeleton ? selectedSkeleton : ""}
                    />

                    <div className=" d-flex ms-auto gap-3 mx-3">
                      <FaArrowRotateLeft
                        size={20}
                        color="var(--text-secondary-color)"
                      />
                      <FaArrowRotateRight
                        size={20}
                        color="var(--text-secondary-color)"
                      />
                    </div>
                  </div>

                  <textarea
                    className={`${styles.secQuestion__txtArea}`}
                    style={{ height: "50vh", width: "100%", padding: "1em" }}
                    placeholder="Write your answer here..."
                    value={text}
                    onChange={handleTextChange}
                    disabled={isTextAreaDisabled}
                  ></textarea>
                </div>
                <div
                  className={`${styles.secQuestion__WordCount} d-flex justify-content-end`}
                >
                  ({wordCount} {wordCount === 1 ? "word" : "words"})
                </div>
              </Col>
            </Row>
          ))}
      </section>

      <section
        className={`${styles.footer} p-3 mt-3`}
        style={{ display: showFooter || showSubmitPopup ? "none" : "block" }}
      >
        <div className="d-flex justify-content-end mx-2">
          <AppButtonIcon
            icon={<BsCheck size={24} color="var(--card-color)" />}
            text="Submit"
            onClick={handleSubmitClick}
            style={{
              color: "var( --card-color)",
              width: "120px",
              height: "40px",
              borderRadius: "38px",
              background: "var( --accent-color)",
            }}
          />
        </div>
        {showSubmitPopup && (
          <SubmitPopup
            show={showSubmitPopup}
            onHide={() => setShowSubmitPopup(false)}
            onSubmit={handlePopupSubmit}
          />
        )}

        {showAnswerPopup && (
          <AnswerPopup
            show={showAnswerPopup}
            onHide={() => setShowAnswerPopup(false)}
          />
        )}
      </section>

      <section
        ref={feedbackRef}
        className={`${styles.feedback}  p-4 mx-4`}
        style={{ display: showFooter ? "block" : "none" }}
      >
        <div
          className="p-4"
          style={{ backgroundColor: "var(--feedback-section-primary)" }}
        >
          <div
            className={`${styles.feedback__title} d-flex gap-2 align-items-center`}
          >
            <p>
              <BsStars size={18} color="var(--text-primary-color)" />
            </p>
            <p className="mt-1">Feedback</p>
          </div>

          <div className={`${styles.feedback__description} mb-4`}>
            The text demonstrates a reasonable attempt to discuss gender
            disparities in further education in Britain, adhering to a
            simplistic and straightforward style typical of an IELTS Band 5
            level. It effectively introduces the topic, highlights societal
            influences on educational choices, and emphasizes the impact of
            stereotypes on enrollment. However, to enhance the response,
            consider incorporating more specific examples or statistics to
            support the claims made. Additionally, ensure clarity in expression
            and coherence between sentences. For instance, the phrase "this
            divergence is evident" could be more explicit in referencing the
            gender gap discussed earlier in the paragraph.
          </div>

          <div className="d-flex gap-3">
            <div className={`${styles.feedback__improveBtn} `}>
              <AppButtonIcon
                icon={<FaArrowTrendUp size={18} color="var(--card-color)" />}
                text="Improve Band"
                style={{
                  color: "var(--card-color)",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "600",
                  width: "160px",
                  height: "40px",
                  padding: "10px",
                  borderRadius: "38px",
                  background: "var(--accent-color)",
                }}
              />
            </div>

            <div>
              <AppButton
                className={`${styles.feedback__viewBtn} `}
                text="View Sample"
                btnType="filled"
                btnSize="md"
              />
            </div>
          </div>
        </div>

        <div
          className="p-4 mt-4"
          style={{ backgroundColor: "var(--feedback-section-secondary)" }}
        >
          <div>
            <div
              className={`${styles.feedback__title} d-flex gap-2 align-items-center`}
            >
              <p>
                <BsStars size={18} color="var(--text-primary-color)" />
              </p>
              <p className="mt-1">Basic Improvements</p>
            </div>

            <div className={`${styles.feedback__description} mb-4`}>
              The text demonstrates a reasonable attempt to discuss gender
              disparities in further education in Britain, adhering to a
              simplistic and straightforward style typical of an IELTS Band 5
              level. It effectively introduces the topic, highlights societal
              influences on educational choices, and emphasizes the impact of
              stereotypes on enrollment. However, to enhance the response,
              consider incorporating more specific examples or statistics to
              support the claims made. Additionally, ensure clarity in
              expression and coherence between sentences. For instance, the
              phrase "this divergence is evident" could be more explicit in
              referencing the gender gap discussed earlier in the paragraph.
            </div>
          </div>

          <div>
            <div
              className={`${styles.feedback__title} d-flex gap-2 align-items-center`}
            >
              <p>
                <BsStars size={18} color="var(--text-primary-color)" />
              </p>
              <p className="mt-1">Advance Improvements</p>
            </div>

            <div className={`${styles.feedback__description} mb-4`}>
              The text demonstrates a reasonable attempt to discuss gender
              disparities in further education in Britain, adhering to a
              simplistic and straightforward style typical of an IELTS Band 5
              level. It effectively introduces the topic, highlights societal
              influences on educational choices, and emphasizes the impact of
              stereotypes on enrollment. However, to enhance the response,
              consider incorporating more specific examples or statistics to
              support the claims made. Additionally, ensure clarity in
              expression and coherence between sentences. For instance, the
              phrase "this divergence is evident" could be more explicit in
              referencing the gender gap discussed earlier in the paragraph.
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default WritingPart1;
