import { Modal } from "react-bootstrap";
import AppButton from "../../../../shared/elements/app-button/app-button";
import styles from "./writingPart1-page.module.scss";

const SubmitPopup: React.FC<{
  show: boolean;
  onHide: () => void;
  onSubmit: () => void;
}> = ({ show, onHide, onSubmit }) => {
  return (
    <div
      className="modal show"
      style={{ display: "block", position: "initial" }}
    >
      <Modal show={show} onHide={onHide} centered>
        <Modal.Header closeButton>
          <Modal.Title className={`${styles.popup__titleTxt}`}>
            Are you sure?
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className={`${styles.popup__bodyTxt}`}>
          <p>
            By clicking "Submit," you are confirming that the information
            provided is accurate and complete.
          </p>
        </Modal.Body>

        <Modal.Footer className="d-flex justify-content-start">
          <AppButton
            className={`${styles.popup__submitBtn}`}
            text="Submit"
            btnType="filled"
            btnSize="md"
            onClick={() => {
              onSubmit();
              onHide();
            }}
          />
          <AppButton
            className={`${styles.popup__cancelBtn}`}
            text="Cancel"
            btnType="bordered"
            btnSize="md"
            onClick={onHide}
          />
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default SubmitPopup;
