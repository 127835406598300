import { createSlice } from '@reduxjs/toolkit';
import { ETheme } from '../../../shared/enums/theme';
import { RootState } from '../../store';

export const initialState = {
  theme: localStorage.getItem('theme') ?? ETheme.LIGHT,
  secondaryNavOpen: false
};

export type AppState = Readonly<typeof initialState>;

export const appSlice = createSlice({
  name: 'app',
  initialState: initialState as AppState,
  reducers: {
    changeTheme(state, action) {
      return {
        ...initialState,
        theme: action.payload as ETheme,
      };
    },
    setSecondaryNavStatus(state, action) {
      return {
        ...initialState,
        secondaryNavOpen: action.payload,
      };
    },
  },
});

export const { changeTheme, setSecondaryNavStatus } = appSlice.actions;

export const selectTheme = (state: RootState) => state.app.theme;
export const selectSecondaryNavStatus = (state: RootState) => state.app.secondaryNavOpen;

export default appSlice.reducer;
