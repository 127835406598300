import { Modal } from "react-bootstrap";
import AppButton from "../../../../shared/elements/app-button/app-button";
import styles from "./writingPart1-page.module.scss";

const OkPopup: React.FC<{
  show: boolean;
  onHide: () => void;
  onOk: (skeletonText: string) => void;
  skeletonText: string;
}> = ({ show, onHide, onOk, skeletonText }) => {
  return (
    <div
      className="modal show"
      style={{ display: "block", position: "initial" }}
    >
      <Modal show={show} onHide={onHide} centered>
        <Modal.Header closeButton>
          <Modal.Title className={`${styles.popup__titleTxt}`}>
            Are you sure?
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className={`${styles.popup__bodyTxt}`}>
          <p>By clicking "Ok," Your text in text area will be erased</p>
        </Modal.Body>

        <Modal.Footer className="d-flex justify-content-start">
          <AppButton
            className={`${styles.popup__submitBtn}`}
            text="Ok"
            btnType="filled"
            btnSize="md"
            onClick={() => {
              onOk(skeletonText);
              onHide();
            }}
          />
          <AppButton
            className={`${styles.popup__cancelBtn}`}
            text="Cancel"
            btnType="bordered"
            btnSize="md"
            onClick={onHide}
          />
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default OkPopup;
