import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store";

export const initialState = {
  isAuthenticated: false,
  account: {} as any,
  errorMessage: null as unknown as string, // Errors returned from server side
  redirectMessage: null as unknown as string,
  sessionHasBeenFetched: false,
  logoutUrl: null as unknown as string,
};

export type AuthenticationState = Readonly<typeof initialState>;

export const authSlice = createSlice({
  name: "auth",
  initialState: initialState as AuthenticationState,
  reducers: {
    clearAuth(state) {
      return {
        ...state,
        isAuthenticated: false,
      };
    },
    setAccount(state, action) {
      return {
        ...state,
        isAuthenticated: true,
        sessionHasBeenFetched: true,
        account: action.payload,
      };
    },
    setLogoutUrl(state, action) {
      return {
        ...state,
        logoutUrl: action.payload,
      };
    },
    setSessionHasBeenFetched(state) {
      return {
        ...state,
        sessionHasBeenFetched: true,
      };
    },
  },
});

export const { clearAuth, setAccount, setLogoutUrl, setSessionHasBeenFetched } = authSlice.actions;

export const selectIsAuthenticated = (state: RootState) =>
  state.auth.isAuthenticated;
export const selectAccount = (state: RootState) => state.auth.account;
export const selectIsSessionHasBeenFetched = (state: RootState) => state.auth.sessionHasBeenFetched;

export default authSlice.reducer;
