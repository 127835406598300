import { Container } from 'react-bootstrap';
import { Outlet } from 'react-router-dom';
import Header from '../header/header';
import ScrollToTop from '../../components/scroll-to-top/scroll-to-top';
import Footer from '../footer/footer';

const LandingLayout = () => {
  return (
    <Container fluid className="p-0 overflow-hidden">
      <div className="landing-layout">
        <Header />
        <div className="landing-layout__body">
          <ScrollToTop />
          <Outlet />
        </div>
        <Footer />
      </div>
    </Container>
  );
};

export default LandingLayout;
