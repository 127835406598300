import { configureStore } from "@reduxjs/toolkit";
import { rootApi } from "./server/api-manager";
import authReducer from "./local/auth/auth-slice";
import appReducer from "./local/app/app-slice";
import { rtkQueryErrorLogger } from "./server/rtk-query-error-logger";
import { TypedUseSelectorHook, useSelector, useDispatch } from "react-redux";

export const store = configureStore({
  reducer: {
    app: appReducer,
    auth: authReducer,
    [rootApi.reducerPath]: rootApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([rootApi.middleware, rtkQueryErrorLogger]),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useAppDispatch = () => useDispatch<AppDispatch>();