import React, { FC } from 'react';
import { Col, Row } from 'react-bootstrap';
import styles from './nav-profile-list-item.module.scss';

export interface IListItem {
  itemName: string;
  id: string;
  path?: string;
}

interface IProps {
  itemList: IListItem[];
  onClick?: (key: string) => void;
  isMobileNav?: boolean;
}

const NavProfileListItem: FC<IProps> = props => {
  return (
    <div className={`${props.isMobileNav ? styles.mobileListCard : styles.listCard}`}>
      {props.itemList.map((item: IListItem) => {
        return (
          <Row key={item.id}>
            <Col>
              <div className={`${styles.subItem} cursor-pointer`} onClick={() => props.onClick && props.onClick(item.path ?? "")}>
                {item.itemName}
              </div>
            </Col>
          </Row>
        );
      })}
    </div>
  );
};

export default NavProfileListItem;
