import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { Outlet, useLocation } from "react-router-dom";
import ScrollToTop from "../../components/scroll-to-top/scroll-to-top";
import AppSecondaryNavBar, { IListItem } from "../../components/nav-bar/app-secondary-nav-bar/app-secondary-nav-bar";
import { useAppDispatch } from "../../../state/store";
import { setSecondaryNavStatus } from "../../../state/local/app/app-slice";

interface SecondaryLayoutProps {
  title : string;
}

const navItems : IListItem[] = [
  {
    id: "1",
    itemName: "Practice Dashboard",
    path: "",
  },
  {
    id: "2",
    itemName: "Practice Tasks",
    path: "tasks",
  },
  {
    id : "3",
    itemName: "Grades",
    path: "",
  },
  {
    id : "4",
    itemName: "Mock Exams",
    path: "",
  },
  {
    id : "5",
    itemName: "Announcements",
    path: "",
  },
];


const SecondaryLayout = (props: SecondaryLayoutProps) => {
  const {hash, pathname} = useLocation();
  console.log( pathname);
  const [open, setOpen] = useState(false);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setSecondaryNavStatus(true));
  }, [dispatch])
 

  const openHandler = () => setOpen(true);
  const closeHandler = () => setOpen(false);

  return (
    <Container fluid className={`secondary-layout px-0 overflow-hidden`}>
      <div className="d-flex">
        <nav className="d-none d-md-flex" style={{ width: "250px" }}>
          <AppSecondaryNavBar title={props.title} itemList={navItems} />
        </nav>
        <section className="main-layout__body">
          <ScrollToTop />
          <div className="main-layout__content">
            <Outlet />
          </div>
        </section>
      </div>
    </Container>
  );
};

export default SecondaryLayout;
