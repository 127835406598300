import React from "react";
import styles from "./header.module.scss";
import { HiOutlineViewGrid } from "react-icons/hi";

interface IProps {
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}

const MenuToggle = (props: IProps) => {
  return (
    <div
      onClick={props.onClick}
      className={`${styles["navbar-toggler"]} d-flex align-items-center justify-content-center border-12`}
    >
      <HiOutlineViewGrid  color={"#ffffff"} />
    </div>
  );
};

export default MenuToggle;
