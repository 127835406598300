import { useEffect } from "react";
import { useAppSelector } from "../../state/store";
import { useLogoutMutation } from "../../state/server/auth/auth-api";

export const Logout = () => {
  const logoutUrl = useAppSelector((state) => state.auth.logoutUrl);

  const [logout] = useLogoutMutation();

  useEffect(() => {
    logout();
    if (logoutUrl) {
      window.location.href = logoutUrl;
    }
  }, [logout, logoutUrl]);

  return <div></div>;
};

export default Logout;
