import { Carousel, Card, Row, Col } from "react-bootstrap";
import styles from "./landing-page.module.scss";

const reviews = [
  {
    img: "Ellipse 32.png",
    name: "Samadhi Weerasinghe",
    designation: "IT Professional",
    review:
      "EnFluent's AI Writing Coach transformed my approach to essay structuring. The skeleton drafting feature helped me organize my thoughts effectively before fleshing out my essays. It's been a major part of my improvement.",
  },
  {
    img: "Ellipse 32.png",
    name: "Isuri Gallage",
    designation: "Graduate Student",
    review:
      "The step-by-step feedback on my writing from EnFluent's AI coach is invaluable. It pinpointed exactly where I needed to improve, allowing me to progress steadily and confidently.",
  },
  {
    img: "Ellipse 32.png",
    name: "Janith Wijesinghe",
    designation: "Electrical Engineer",
    review:
      "As a non-native English speaker, writing was my biggest hurdle. EnFluent’s tailored writing exercises helped me improve drastically. The feedback from the AI analysis was spot-on and very helpful.",
  },
];

const SmallScreenCarousel = () => (
  <Carousel>
    {reviews.map((rev) => (
      <Carousel.Item className="p-3" key={rev.name}>
        <Card
          className="p-3 h-100"
          style={{
            borderRadius: "12px",
            border: "none",
            filter: " drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.08))",
          }}
        >
          <Card.Body>
            <Row className="align-items-center px-3">
              <Col xs="auto" className="p-0">
                <img src={rev.img} alt="" />
              </Col>
              <Col className="align-items-center">
                <div>
                  <h6 className={`${styles.card__name}`}>{rev.name}</h6>
                  <h6 className={`${styles.card__title}`}>{rev.designation}</h6>
                </div>
              </Col>
            </Row>
            <Card.Text>{rev.review}</Card.Text>
          </Card.Body>
        </Card>
      </Carousel.Item>
    ))}
  </Carousel>
);

const LargeScreenCarousel = () => (
  <Carousel>
    <Carousel.Item>
        <Row className="px-2">
          {reviews.map((rev) => (
            <Col lg={4} className="py-3" key={rev.name}>
              <Card
                className="p-3 h-100"
                style={{
                  borderRadius: "12px",
                  border: "none",
                  filter: " drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.08))",
                }}
              >
                <Card.Body>
                  <Row className="px-3">
                    <Col xs="auto" className="p-0 ">
                      <img src={rev.img} alt="review-person" />
                    </Col>
                    <Col>
                      <h6 className={`${styles.card__name} m-0`}>{rev.name}</h6>
                      <h6 className={`${styles.card__title} `}>
                        {rev.designation}
                      </h6>
                    </Col>
                  </Row>
                  <Card.Text>{rev.review}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
    </Carousel.Item>
  </Carousel>
);

const CarousalComponent = () => (
  <div>
    <div className="d-md-none">
      <SmallScreenCarousel />
    </div>
    <div className="d-none d-md-block">
      <LargeScreenCarousel />
    </div>
  </div>
);

export default CarousalComponent;
