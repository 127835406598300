import { useEffect, useState } from "react";
import { Accordion, Col, Container, Row } from "react-bootstrap";
import styles from "./landing-page.module.scss";
import AppButton from "../../shared/elements/app-button/app-button";
import {
  BsChatLeftText,
  BsClipboardData,
  BsFileText,
  BsSpeedometer,
} from "react-icons/bs";
import AppInput from "../../shared/elements/app-input/app-input";
import CarousalComponent from "./carousal";
import { useLocation } from "react-router-dom";

const LandingPage = () => {
  const [email, setEmail] = useState("");

  const location = useLocation();

  useEffect(() => {
    const hash = location.hash;
    if (hash) {
      const element = document.getElementById(hash.substring(1));
      if (element) element.scrollIntoView();
    }
  }, [location]);

  return (
    <div className={`${styles.homeContainer} fade-in`}>
      <section
        className={` ${styles.sectionOne} d-flex justify-content-center align-items-center my-5`}
      >
        <Container className="py-3 py-sm-5 py-xl-0">
          <Row className="align-items-center">
            <Col className="col-12 col-xl-6 col-lg-6">
              <h1
                className={`${styles.sectionOne__text1} display-4 text-center text-lg-start`}
              >
                Conquer IELTS with EnFluent:
              </h1>
              <h1
                className={`${styles.sectionOne__text1} display-4 text-center text-lg-start  fw-bold`}
              >
                Your Personalized English Coach
              </h1>
              <p
                className={` ${styles.sectionOne__text2} text-center text-lg-start  fw-normal py-3`}
              >
                Your Partner in IELTS Preparation with Tailored Practice Tasks
              </p>

              <Row className="d-flex flex-column flex-sm-row  justify-content-sm-center justify-content-lg-start mx-5 m-sm-0">
                <Col className={`col-auto mx-3 mx-lg-0 p-lg-0`}>
                  <AppButton
                    text="Start Your Journey"
                    btnSize="lg"
                    onClick={() => {}}
                  />
                </Col>
                <Col className={`col-auto mx-3  pt-4 p-sm-0`}>
                  <AppButton
                    className={`${styles.sectionOne__btn2} px-3 text-nowrap`}
                    text="Learn More"
                    btnType="onlyText"
                    btnSize="lg"
                    onClick={() => {}}
                  />
                </Col>
              </Row>
            </Col>
            <Col className="col-12 col-xl-6 col-lg-6 text-center  p-5">
              <img
                src={"reading_books.png"}
                alt="An illustration reading books"
              />
            </Col>
          </Row>
        </Container>
      </section>

      <section className={` ${styles.sectionDividerOne} p-4`}>
        <Container>
          <Row className="align-items-center p-5 mx-md-5 d-flex justify-content-between">
            <Col className="col-12 col-sm-6 col-lg-8 ps-lg-5">
              <p
                className={`${styles.sectionDividerOne__text1} text-center mb-0 text-sm-start`}
              >
                Accelerate your English learning with EnFluent.
              </p>
              <p
                className={` ${styles.sectionDividerOne__text2}   mb-0 text-center text-sm-start pt-1`} //text-white text-sm-start fw-normal
              >
                Get comprehensive feedback for practices with AI
              </p>
            </Col>
            <Col className="col-12 col-sm-6 col-lg-4 mt-4 mt-md-0 px-5 d-flex justify-content-center justify-content-lg-end">
              <Row>
                <Col className={`text-center`}>
                  <AppButton
                    className={`${styles.sectionDividerOne__btn1}  pb-4 `}
                    text="Practice"
                    btnType="bordered"
                    btnSize="sm"
                    onClick={() => {}}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>

      <section className={`${styles.sectionTwo}  pt-5 mt-5 text-center`}>
        <Container className="pt-5 px-5 mt-5">
          <Row className="px-5 mx-5">
            <h1 className={`${styles.sectionTwo__text1}`}>Discover EnFluent</h1>
            <h5 className={`${styles.sectionTwo__text2}`}>
              Your Gateway to IELTS Success
            </h5>
            <p className={`${styles.sectionTwo__text3}`}>
              EnFluent redefines the way you prepare for the IELTS exam.
              Designed with your language learning needs in mind, our app offers
              a holistic approach to mastering English. Join a community of
              learners who have transformed their IELTS preparation journey with
              our innovative, user-centric platform.
            </p>
          </Row>
        </Container>
      </section>

      <section className={`${styles.sectionTwoRow2} pt-5 mt-5 text-end`}>
        <Container>
          <Row className="align-items-center d-flex justify-content-center">
            <Col className="col-12 col-xl-6 col-lg-6">
              <h1
                className={`${styles.sectionTwoRow2__text1} text-center text-lg-end`}
              >
                Refine Your Writing with
              </h1>
              <h1
                className={`${styles.sectionTwoRow2__text1} text-center text-lg-end`}
              >
                Personalized Coaching
              </h1>

              <p
                className={`${styles.sectionTwoRow2__text2} text-center text-lg-end`}
              >
                Harness the power of EnFluent's Writing Coach to elevate your
                writing skills. Our intelligent platform provides you with
                real-time feedback, identifies your common mistakes, and
                provides tailored guidance to conquer IELTS
              </p>
            </Col>

            <Col className="col-12 col-xl-6 col-lg-6 text-center  py-3">
              <img
                src={"two people and note.png"}
                alt="An illustration two people and note"
              />
            </Col>
          </Row>
        </Container>
      </section>

      <section className={`${styles.sectionTwoRow2} py-5 my-5 text-end`}>
        <Container>
          <Row className="align-items-center d-flex justify-content-center">
            <Col className="col-12 col-xl-6 col-lg-6 order-2 order-lg-1 text-center py-3">
              <img
                src={"two people talking.png"}
                alt="An illustration two people talking"
              />
            </Col>

            <Col className="col-12 col-xl-6 col-lg-6 order-1 order-lg-2 text-center text-lg-start">
              <h1 className={`${styles.sectionTwoRow2__text0} mx-auto mx-lg-0`}>
                Coming Soon!
              </h1>
              <h1 className={`${styles.sectionTwoRow2__text1} `}>
                Practice Speaking with Confidence
              </h1>

              <p className={`${styles.sectionTwoRow2__text2} `}>
                Conquer the speaking section of the IELTS with EnFluent's
                Speaking Partner. Engage in realistic conversations and receive
                instant feedback. Our advanced AI simulates various speaking
                scenarios, allowing you to practice comfortably and gain
                confidence in your spoken English
              </p>
            </Col>
          </Row>
        </Container>
      </section>

      <section
        className={` ${styles.sectionDividerTwo} d-flex py-5  justify-content-center`}
      >
        <Container className="px-5 my-5">
          <Row className="align-items-center">
            <Col>
              <h1
                className={`${styles.sectionDividerTwo__text1} text-center px-2`}
              >
                What’s Unique About EnFluent?
              </h1>

              <p
                className={`${styles.sectionDividerTwo__text2} text-center px-2`}
              >
                EnFluent's cutting-edge AI technology analyzes your language
                patterns, identifying strengths and areas for growth. It
                provides actionable insights and suggestions, empowering you to
                make meaningful progress in your IELTS preparation.
              </p>
              <Row className={`mt-5 w-100 mx-1`}>
                <Col className={`col-12 col-lg-6 p-lg-3`}>
                  <div
                    className={`d-flex gap-3 justify-content-lg-end align-items-center`}
                  >
                    <div className={styles.circle}>
                      <BsChatLeftText
                        size={32}
                        color="var(--icon-fill-color)"
                      />
                    </div>

                    <div>
                      <h1
                        className={`${styles.sectionDividerTwoRow2__text1} mb-0 `}
                      >
                        Targeted Feedback for Improvement
                      </h1>

                      <p className={`${styles.sectionDividerTwo__text2} mb-0 `}>
                        Receive specific, step-by-step feedback on writing
                        exercises, focusing precisely on areas that need
                        refinement for continual skill enhancement.
                      </p>
                    </div>
                  </div>
                </Col>

                <Col className={`col-12 col-lg-6 mt-4 mt-lg-0 p-lg-3`}>
                  <div
                    className={`d-flex gap-3 align-items-center justify-content-start`}
                  >
                    <div className={styles.circle}>
                      <BsSpeedometer size={32} color="var(--icon-fill-color)" />
                    </div>
                    <div>
                      <h1
                        className={`${styles.sectionDividerTwoRow2__text1} mb-0 `}
                      >
                        Benchmarking with Sample Answers
                      </h1>

                      <p className={`${styles.sectionDividerTwo__text2} mb-0 `}>
                        Gain access to a range of sample answers at different
                        band levels, providing clear benchmarks and insights
                        into high-scoring writing techniques.
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className={`mt-4 w-100 mx-1`}>
                <Col className={`col-12 col-lg-6 p-lg-3`}>
                  <div
                    className={`d-flex gap-3 justify-content-lg-end align-items-center`}
                  >
                    <div className={styles.circle}>
                      <BsFileText size={32} color="var(--icon-fill-color)" />
                    </div>

                    <div>
                      <h1
                        className={`${styles.sectionDividerTwoRow2__text1} mb-0 `}
                      >
                        Structured Writing Development
                      </h1>

                      <p className={`${styles.sectionDividerTwo__text2} mb-0 `}>
                        Enhance essay organization and structure with the AI
                        Writing Coach's skeleton drafting feature, guiding you
                        to articulate your ideas more effectively.
                      </p>
                    </div>
                  </div>
                </Col>
                <Col className={`col-12 col-lg-6 mt-4 mt-lg-0 p-lg-3`}>
                  <div
                    className={`d-flex gap-3 align-items-center justify-content-start`}
                  >
                    <div className={styles.circle}>
                      <BsClipboardData
                        size={32}
                        color="var(--icon-fill-color)"
                      />
                    </div>
                    <div>
                      <h1
                        className={`${styles.sectionDividerTwoRow2__text1} mb-0 `}
                      >
                        Data-Driven Progress Tracking
                      </h1>

                      <p className={`${styles.sectionDividerTwo__text2} mb-0 `}>
                        Monitor your improvement with detailed analytics and
                        insights powered by advanced AI technology.
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>

      <section className={`${styles.stories} p-5`}>
        <Container className="my-5">
          <div className={`${styles.stories__title}`}>
            <h1>Real Success Stories from </h1>
            <h1>EnFluent Users </h1>
          </div>
        </Container>
        <Container>
          <CarousalComponent />
        </Container>
      </section>

      <section className={`${styles.faqs} py-5`}>
        <Container className="my-5">
          <h1 className={`${styles.faqs__title}`}>FAQs</h1>
          <Accordion
            defaultActiveKey="0"
            className={`${styles.faqs__accordion} py-2`}
          >
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <div>
                  <p className={`${styles.faqs__text1}`}>Question 01</p>
                  <p className={`${styles.faqs__text2} mt-3`}>
                    How does the AI Writing Coach work?
                  </p>
                </div>
              </Accordion.Header>
              <Accordion.Body className={`${styles.faqs__text3} `}>
                The writing coach looks the same as your writing tasks in the
                IELTS exam. However, it comes with advanced analysis and
                feedback. The AI-based writing coach sends you detailed feedback
                on your answers. The coach assesses various aspects of your
                writing, such as grammar, coherence, and vocabulary usage,
                offering detailed suggestions for improvement. You can get
                graded and also ask questions like “If I am to improve my score
                by 1 band, what should I do?”. These features help you
                progressively build your writing proficiency in a targeted and
                efficient manner.
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="1" className={`${styles.faqs} mt-3`}>
              <Accordion.Header className={`${styles.faqs__header}`}>
                <div>
                  <p className={`${styles.faqs__text1}`}>Question 02</p>
                  <p className={`${styles.faqs__text2} mt-3`}>
                    How does EnFluent track my progress?
                  </p>
                </div>
              </Accordion.Header>
              <Accordion.Body className={`${styles.faqs__text3}`}>
                The writing coach looks the same as your writing tasks in the
                IELTS exam. However, it comes with advanced analysis and
                feedback. The AI-based writing coach sends you detailed feedback
                on your answers. The coach assesses various aspects of your
                writing, such as grammar, coherence, and vocabulary usage,
                offering detailed suggestions for improvement. You can get
                graded and also ask questions like “If I am to improve my score
                by 1 band, what should I do?”. These features help you
                progressively build your writing proficiency in a targeted and
                efficient manner.
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="2" className={`${styles.faqs} mt-3`}>
              <Accordion.Header className={`${styles.faqs__header}`}>
                <div>
                  <p className={`${styles.faqs__text1}`}>Question 03</p>
                  <p className={`${styles.faqs__text2} mt-3`}>
                    I have no prior knowledge of electronics or programming.
                    Will I be able to do this course?
                  </p>
                </div>
              </Accordion.Header>
              <Accordion.Body className={`${styles.faqs__text3}`}>
                The writing coach looks the same as your writing tasks in the
                IELTS exam. However, it comes with advanced analysis and
                feedback. The AI-based writing coach sends you detailed feedback
                on your answers. The coach assesses various aspects of your
                writing, such as grammar, coherence, and vocabulary usage,
                offering detailed suggestions for improvement. You can get
                graded and also ask questions like “If I am to improve my score
                by 1 band, what should I do?”. These features help you
                progressively build your writing proficiency in a targeted and
                efficient manner.
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Container>
      </section>

      <section
        id="contact"
        className={`${styles.contacts} py-5 mt-5 px-lg-5 mx-md-5`}
      >
        <Container className="px-5">
          <h1 className={`${styles.contacts__title} text-center`}>
            Contact Us
          </h1>

          <div className={`${styles.contacts} px-md-5 mx-md-5`}>
            <AppInput
              label="Name"
              placeholder="Enter your name"
              isRequired={true}
            />
            <AppInput
              className="mt-3"
              label="Email"
              placeholder="Enter your email"
              isRequired={true}
            />
            <AppInput
              className="mt-3"
              label="Contact Number (optional)"
              placeholder="Enter your contact number"
            />
            <AppInput
              className={`${styles.contacts__msg} mt-3`}
              label="Message"
              placeholder="Enter your message"
            />
            <div className="d-flex justify-content-md-start justify-content-center">
              <AppButton
                className={`${styles.contacts__btn} mt-5`}
                text="Submit"
                btnType="filled"
                btnSize="md"
                onClick={() => {}}
              />
            </div>
          </div>
        </Container>
      </section>
    </div>
  );
};

export default LandingPage;
