import React, { useEffect } from 'react';
import { Col, Container, Nav, Navbar, Row } from 'react-bootstrap';
import { BsBell, BsBook, BsBoxArrowRight, BsCash, BsGear, BsHouseDoor } from 'react-icons/bs';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import ThemeToggle from '../../theme-toggle/theme-toggle';
import PrimaryNavBarMenuItem from '../app-primary-nav-bar/app-primary-nav-bar-menu-item/app-primary-nav-bar-menu-item';
import styles from './app-primary-mobile-nav-bar.module.scss';
import { useAppSelector } from '../../../../state/store';
import { AUTHORITIES } from '../../../../config/constants';
import AppButton from '../../../elements/app-button/app-button';
import { hasAnyAuthority } from '../../auth/private-route';
import { IListItem } from '../app-secondary-nav-bar/app-secondary-nav-bar';
import NavProfile from '../../profile-views/nav-profile';

const navLinks: {
  path: string;
  text: string;
}[] = [
  {
    path: '/',
    text: 'Home',
  },
  {
    path: '/catalog',
    text: 'Program Catalog',
  },
  {
    path: '/contact-us',
    text: 'Contact Us',
  },
];

interface IProps {
  closeHandler: () => void;
  isInMyCourses?: boolean;
}

const AppPrimaryMobileNavBar = (props: IProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const authState = useAppSelector(state => state.auth);
  const isAuthorizedAdmin = hasAnyAuthority(authState.account.authorities, [AUTHORITIES.ADMIN]);
  const [active, setActive] = React.useState<string>(location.pathname);

  useEffect(() => {
    setActive(location.pathname);
  }, [location.pathname]);

  const itemArray: IListItem[] = [
    {
      itemName: 'My Courses',
      id: '1',
      path: isAuthorizedAdmin ? '/admin/courses' : '/student/courses',
    },
    {
      itemName: 'LMS Home',
      id: '2',
      path: '/',
    },
    {
      itemName: 'Notices',
      id: '3',
      path: '/',
    },
    {
      itemName: 'Log Out',
      id: '5',
      path: '/logout',
    },
  ];

  const topItemsAdmin = [
    { title: 'Home', link: '/', icon: BsHouseDoor },
    { title: 'Practice', link: '/admin/courses', icon: BsBook },
    { title: 'Notices', link: '/notices', icon: BsBell },
    { title: 'Payment', link: `/admin/payment`, icon: BsCash },
    {
      title: `${authState.account.firstName} ${authState.account.lastName}`,
      link: '/admin/account/profile',
      image: `${authState.account.imageUrl}`,
    },
  ];

  const topItemsStudent = [
    { title: 'Home', link: '/', icon: BsHouseDoor },
    { title: 'Practice', link: '/student/courses', icon: BsBook },
    { title: 'Notices', link: '/notices', icon: BsBell },
    {
      title: `${authState.account.firstName} ${authState.account.lastName}`,
      link: '/student/account/profile',
      image: `${authState.account.imageUrl}`,
    },
  ];

  const bottomItems = [
    {
      title: 'Settings',
      link: isAuthorizedAdmin ? '/admin/settings/coupons' : "",
      icon: BsGear,
    },
    { title: 'Log Out', icon: BsBoxArrowRight, link: '/logout' },
  ];

  const handleItemClick = (path: string) => {
    navigate(path);
  };

  return (
    <Row>
        <Col>
          {props.isInMyCourses ? (
            <Row className={`${styles.navBar} pt-5`}>
              <Col>
                {(isAuthorizedAdmin ? topItemsAdmin : topItemsStudent).map((item, index) => (
                  <PrimaryNavBarMenuItem key={index} {...item} isMobileNav userProfileImage={item.image} />
                ))}
                {bottomItems.map((item, index) => (
                  <PrimaryNavBarMenuItem key={index} {...item} isMobileNav isNormalIcon />
                ))}
              </Col>
            </Row>
          ) : (
            <Navbar>
              <Container>
                <Navbar.Collapse id="responsive-navbar-nav">
                  <Row className="align-items-center justify-content-center">
                    <Nav className={`${styles.navBar}`}>
                      <Col xs={12}>
                        {navLinks.map((link, index) => (
                          <NavLink
                            key={index}
                            to={link.path}
                            onClick={props.closeHandler}
                            className={`text-decoration-none d-flex align-items-center pb-4 ${styles.navBarItem} ${
                              active === link.path ? `${styles['nav-hover-selected']}` : `${styles['nav-hover']}`
                            }`}
                          >
                            {link.text}
                          </NavLink>
                        ))}
                      </Col>
                    </Nav>

                    <Col xs={12} className={`d-flex pb-4 ${styles.navBarItem}`}>
                      <ThemeToggle iconColor />
                    </Col>
                    {authState.isAuthenticated ? (
                      <Col xs={12} className={`align-items-center pb-4 ${styles.navBarItem}`}>
                        <NavProfile
                          firstName={authState.account.firstName}
                          lastName={authState.account.lastName}
                          items={itemArray}
                          image={authState.account.imageUrl}
                          onClick={handleItemClick}
                          isMobileNav
                        />
                      </Col>
                    ) : (
                      <Col xs={4} className={`${styles['app-button-container']}`}>
                        <AppButton text="LOGIN" btnSize="sm" onClick={() => navigate('/login')} btnType="white" />
                      </Col>
                    )}
                  </Row>
                </Navbar.Collapse>
              </Container>
            </Navbar>
          )}
        </Col>
      </Row>
  );
};

export default AppPrimaryMobileNavBar;
