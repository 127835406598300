import { rootApi } from "../api-manager";

interface Answer {
  answerId: number;
  chaptorId: number;
  userId: string;
  answer: string;
  generatedAns1: string;
  generatedAns2: string;
}

export const answerAPI = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllAnswers: builder.query<Answer[], void>({
      query: () => "answers",
    }),

    getAnswerById: builder.query<Answer, number>({
      query: (answerId) => `answers/${answerId}`,
    }),

    createAnswer: builder.mutation<Answer, Partial<Answer>>({
      query: (newAnswer) => ({
        url: "answers",
        method: "POST",
        body: newAnswer,
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetAllAnswersQuery,
  useGetAnswerByIdQuery,
  useCreateAnswerMutation,
} = answerAPI;
