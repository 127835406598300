import React from "react";
import { Col, Row } from "react-bootstrap";
import AppButton from "../../../shared/elements/app-button/app-button";
import styles from "./home-page.module.scss";
import { BsPen } from "react-icons/bs";

const HomePage: React.FC = () => {
  return (
    <div>
      <section className="mx-5">
        <h1
          className="mt-5"
          style={{
            fontSize: "24px",
            fontWeight: "700",
            color: "var(--text-primary-color",
          }}
        >
          Dashboard
        </h1>
        <h1
          className="mt-5"
          style={{
            fontSize: "18px",
            fontWeight: "600",
            color: "var(--text-primary-color",
          }}
        >
          Recent Results
        </h1>
        <Row className={`${styles.cards} justify-content-center `}>
          <Col
            className={`${styles.cards__card}  m-2 col-6 col-md-6 col-lg-3 `}
          >
            <div>
              <Row>
                <div className="d-flex gap-2">
                  <div className={`${styles.cards__icons}`}>
                    <BsPen size={32} color="#65E280" />
                  </div>
                  <div className={`${styles.cards__title}`}>Writing</div>
                </div>
                <div className={`${styles.cards__des}`}>6.6</div>
              </Row>
            </div>
            <div className="d-flex justify-content-end justify-content-md-start ">
              <AppButton
                className={`${styles.cards__btn} `}
                text="Continue"
                btnType="filled"
                btnSize="md"
              />
            </div>
          </Col>

          <Col
            className={`${styles.cards__card}  m-2 col-6 col-md-6 col-lg-3 `}
          >
            <div>
              <Row>
                <div className="d-flex gap-2">
                  <div className={`${styles.cards__icons}`}>
                    <BsPen size={32} color="#944FDA" />
                  </div>
                  <div className={`${styles.cards__title}`}>Reading</div>
                </div>
                <div className="d-flex justify-content-start mt-5">
                  <AppButton
                    className={`${styles.cards__btnHide}`}
                    text="View"
                    btnType="filled"
                    btnSize="md"
                  />
                </div>
              </Row>
            </div>
            <div className={`${styles.cards__comingSoon}`}>
              Coming Soon Coming Soon Coming Soon
            </div>
          </Col>

          <Col className={`${styles.cards__card} m-2 col-6 col-md-6 col-lg-3`}>
            <div>
              <Row>
                <div className="d-flex gap-2">
                  <div className={`${styles.cards__icons}`}>
                    <BsPen size={32} color="#DACC4F" />
                  </div>
                  <div className={`${styles.cards__title}`}>Listening</div>
                </div>
                <div className="d-flex justify-content-start mt-5">
                  <AppButton
                    className={`${styles.cards__btnHide}`}
                    text="View"
                    btnType="filled"
                    btnSize="md"
                  />
                </div>
              </Row>
            </div>
            <div className={`${styles.cards__comingSoon}`}>
              Coming Soon Coming Soon Coming Soon
            </div>
          </Col>

          <Col className={`${styles.cards__card} m-2 col-6 col-md-6 col-lg-3 `}>
            <div>
              <Row>
                <div className="d-flex gap-2">
                  <div className={`${styles.cards__icons}`}>
                    <BsPen size={32} color="#4FDAD2" />
                  </div>
                  <div className={`${styles.cards__title}`}>Speaking</div>
                </div>
                <div className="d-flex justify-content-start mt-5">
                  <AppButton
                    className={`${styles.cards__btnHide}`}
                    text="View"
                    btnType="filled"
                    btnSize="md"
                  />
                </div>
              </Row>
            </div>
            <div className={`${styles.cards__comingSoon}`}>
              Coming Soon Coming Soon Coming Soon
            </div>
          </Col>
        </Row>
      </section>

      <section className={`${styles.view} m-5`}>
        <div className={`${styles.view__heading} mb-3`}>Recently Viewed</div>
        <Row className={`${styles.view__row} align-items-center p-4 mb-4`}>
          <Col className="col-md-10 col-12">
            <div className="d-flex justify-content-start gap-2">
              <div>
                <BsPen size={32} color="var(--icon-color)" />
              </div>
              <div className={`${styles.view__title} px-2`}>
                Sample practice 2
              </div>
            </div>
          </Col>

          <Col className="col-md-2 col-12 mt-lg-0 mt-2 d-flex justify-content-lg-end justify-content-start">
            <AppButton
              className={`${styles.view__btnType1}`}
              text="Continue"
              btnType="filled"
              btnSize="md"
            />
          </Col>
        </Row>

        <div className={`${styles.view__heading} mb-3`}>Upcoming Exercises</div>
        <Row className={`${styles.view__row} align-items-center p-4 mb-4`}>
          <Col className="col-md-10 col-12">
            <div className="d-flex justify-content-start gap-2">
              <div>
                <BsPen size={32} color="var(--icon-color)" />
              </div>
              <div className={`${styles.view__title} px-2`}>
                Sample practice 3
              </div>
            </div>
          </Col>

          <Col className="col-md-2 col-12 mt-lg-0 mt-2 d-flex justify-content-lg-end justify-content-start">
            <AppButton
              className={`${styles.view__btnType2}`}
              text="Start"
              btnType="filled"
              btnSize="md"
            />
          </Col>
        </Row>

        <Row className={`${styles.view__row} align-items-center p-4 mb-4`}>
          <Col className="col-md-10 col-12">
            <div className="d-flex justify-content-start gap-2">
              <div>
                <BsPen size={32} color="var(--icon-color)" />
              </div>
              <div className={`${styles.view__title} px-2`}>
                Sample practice 4
              </div>
            </div>
          </Col>

          <Col className="col-md-2 col-12 mt-lg-0 mt-2 d-flex justify-content-lg-end justify-content-start">
            <AppButton
              className={`${styles.view__btnType2}`}
              text="Start"
              btnType="filled"
              btnSize="md"
            />
          </Col>
        </Row>
      </section>
    </div>
  );
};

export default HomePage;
