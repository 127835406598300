import { isRejectedWithValue, Middleware } from "@reduxjs/toolkit";
import { setSessionHasBeenFetched } from "../local/auth/auth-slice";

export const rtkQueryErrorLogger: Middleware = (api) => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    const error: any = action.payload;
    switch (error.status) {
      case 500:
        console.log(error);
        break;
      case 401:
        api.dispatch(setSessionHasBeenFetched());
        break;
      default:
        break;
    }
  }

  return next(action);
};
