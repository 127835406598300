import React from "react";
import { Col, Container, Row } from "react-bootstrap";

import styles from "./footer.module.scss";

const Footer = () => {
  return (
    <div className={`${styles["footer-container"]}`}>
      <Container className="py-5">
        <Row className="d-flex justify-content-center align-items-center">
          <Col className="col-12 col-lg-6">
            <Row>
              <Col className="col-12 col-lg-auto text-center mb-3 mb-lg-2">
                <img
                  src={"logo_dark.png"}
                  alt="enfluent logo"
                  width={170}
                  className="img-fluid"
                />
              </Col>
              <Col className="col-12 col-lg">
                <Row>
                  <Col>
                    <p
                      className={`${styles["footer-slogan"]} mb-2 text-center text-lg-start fw-normal`}
                    >
                      Your Gateway to IELTS Success
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <p
                      className={`${styles["footer-copyrights"]} mb-0 text-center text-lg-start fw-normal`}
                    >
                      &#169; 2023-2024 enfluent.lk
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col className="col-12 col-md-6 col-lg-3 mt-4 mt-lg-0 ">
            <Row>
              <div className="d-flex justify-content-center justify-content-md-end gap-4 gap-lg-5 px-5 px-lg-4 px-xl-5">
                <p
                  className={`${styles["footer-contactUs"]} text-end text-lg-end mb-0 mx-`}
                >
                  Practice
                </p>
                <p
                  className={`${styles["footer-contactUs"]} text-start text-lg-start mb-0 mx-lg-0 mx-4`}
                >
                  Contact Us
                </p>
              </div>
            </Row>
          </Col>
          <Col className="col-12 col-md-6 col-lg-3 mt-4 mt-lg-0">
            <Row>
              <Col>
                <p
                  className={`${styles["footer-followUs"]} mb-0 text-center text-lg-start fw-bold`}
                >
                  Follow Us
                </p>
              </Col>
            </Row>
            <Row className="justify-content-center justify-content-lg-start">
              <Col className="col-auto">
                <a href="https://www.facebook.com/enfluentlk/">
                  <img src={"social/fb.svg"} alt="Facebook Icon" />
                </a>
              </Col>
              <Col className="col-auto">
                <a href="https://www.instagram.com/enfluentlk/">
                  <img src={"social/ig.svg"} alt="Instagram Icon" />
                </a>
              </Col>
              <Col className="col-auto">
                <a href="https://www.linkedin.com/company/enfluentlk/">
                  <img src={"social/in.svg"} alt="LinkedIn Icon" />
                </a>
              </Col>
              <Col className="col-auto">
                <a href="https://twitter.com/enfluentlk">
                  <img src={"social/twitter.svg"} alt="Twitter Icon" />
                </a>
              </Col>
              <Col className="col-auto">
                <a href="https://www.youtube.com/@enfluentlk">
                  <img
                    src={"social/youtube.svg"}
                    color="var(--accent-color)"
                    alt="Youtube Icon"
                  />
                </a>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Footer;
