import {
  BsBell,
  BsBook,
  BsBoxArrowRight,
  BsGear,
  BsHouseDoor,
} from "react-icons/bs";
import PrimaryNavBarMenuItem from "./app-primary-nav-bar-menu-item/app-primary-nav-bar-menu-item";
import styles from "./app-primary-nav-bar.module.scss";
import { useAppSelector } from "../../../../state/store";
import { hasAnyAuthority } from "../../auth/private-route";
import { AUTHORITIES } from "../../../../config/constants";
import { selectSecondaryNavStatus } from "../../../../state/local/app/app-slice";
interface IProps {
  imagePath: string;
  userFirstName: string;
  userLastName: string;
}

const AppPrimaryNavBar = (props: IProps) => {
  const authState = useAppSelector((state) => state.auth);
  const secondaryNavStatus = useAppSelector(selectSecondaryNavStatus);

  const isAuthorizedAdmin = hasAnyAuthority(authState.account.authorities, [
    AUTHORITIES.ADMIN,
  ]);

  const topItemsAdmin = [{ title: "Home", link: "/", icon: BsHouseDoor }];

  const topItemsStudent = [
    { title: "Home", link: "/app/home", icon: BsHouseDoor },
    { title: "Practice", link: "/app/practice", icon: BsBook },
    { title: "Notices", link: "/notices", icon: BsBell },
  ];

  const bottomItems = [
    {
      title: `${props.userFirstName}\n ${props.userLastName}`,
      link: isAuthorizedAdmin
        ? "/admin/account/profile"
        : "/app/student/account/profile",
      image: `${props.imagePath || "/default-profile.png"}`,
    },
    {
      title: "Settings",
      link: isAuthorizedAdmin ? "/admin/settings" : "/student/settings",
      icon: BsGear,
    },
    { title: "Log Out", icon: BsBoxArrowRight, link: "/logout" },
  ];

  return (
    <div
      className={`vh-100 d-flex flex-column justify-content-between py-4 ${styles.primaryNavBar}`}
      style={!secondaryNavStatus ? { background: "var(--card-color)" } : {}}
    >
      <div>
        <div>
          <img
            src="/logo-min.png"
            alt="enfluent Logo"
            className={`d-block mx-auto mb-3 mt-2 ${styles.logoImg}`}
          />
        </div>
        <div>
          {(isAuthorizedAdmin ? topItemsAdmin : topItemsStudent).map((item) => (
            <PrimaryNavBarMenuItem key={item.link} {...item} />
          ))}
        </div>
      </div>
      <div>
        {bottomItems.map((item) => (
          <PrimaryNavBarMenuItem
            key={item.link}
            {...item}
            userProfileImage={item.image}
          />
        ))}
      </div>
    </div>
  );
};

export default AppPrimaryNavBar;
