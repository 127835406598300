import { Accordion, Col, Row } from "react-bootstrap";
import { BsList, BsPen, BsPlay } from "react-icons/bs";
import styles from "../practice/practice-page.module.scss";
import ProgressBar from "./progressBar";
import AccordionItem from "./accordionItem";
import { useGetAllLessonsQuery } from "../../../state/server/lessons/lesson-api";
import { useParams } from "react-router-dom";

const TasksPage = () => {
  const params = useParams();
  console.log(params);

  const { data, isLoading, isFetching, isError } = useGetAllLessonsQuery(
    parseInt(params.courseId as string)
  );

  return (
    <div>
      <section className="px-4">
        <div className="d-flex align-items-center pt-2 pb-5">
          <div>
            <BsList size={40} color="var(--accent-color)" />
          </div>

          <p
            style={{
              color: "var(--text-primary-color)",
              fontWeight: "600",
              fontSize: "16px",
            }}
            className="mt-3 mx-2"
          >
            Practice Tasks
          </p>
        </div>

        <div className={`${styles.cards} mb-3 px-4 py-2`}>
          <Row className="align-items-center">
            <Col className="col-12 col-lg-10">
              <Row>
                <div className="d-flex gap-2">
                  <div className={`${styles.cards__icons}`}>
                    <BsPen size={32} color="#65E280" />
                  </div>
                  <p className={`${styles.cards__title}`}>writing</p>
                </div>
                <p className={`${styles.cards__des} mt-3`}>
                  The Academic Writing test consists of two writing tasks of 150
                  words and 250 words.
                </p>
              </Row>
            </Col>
          </Row>
          <ProgressBar now={65} />
        </div>
      </section>

      <section
        style={{ backgroundColor: "var(--background-color)" }}
        className="px-4"
      >
        <p
          style={{
            color: "var(--text-primary-color)",
            fontWeight: "400",
            fontSize: "16px",
          }}
          className="px-2 pt-4 pb-2"
        >
          Course Material
        </p>
        <div className="">
          <Accordion defaultActiveKey="0">
            {data?.content.map((item) => (
              <AccordionItem key={item.id} {...item} />
            ))}
          </Accordion>
        </div>
      </section>
    </div>
  );
};

export default TasksPage;
