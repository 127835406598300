import { useEffect } from "react";
import { useLocation } from "react-router";
import { REDIRECT_URL, getLoginUrl } from "../../shared/utils/url-utils";
import { Navigate } from "react-router-dom";
import { AUTHORITIES } from "../../config/constants";
import { hasAnyAuthority } from "../../shared/components/auth/private-route";
import { useAppSelector } from "../../state/store";
import {
  selectAccount,
  selectIsAuthenticated,
} from "../../state/local/auth/auth-slice";

export const LoginRedirect = () => {
  const pageLocation = useLocation();
  const location = useLocation();

  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const account = useAppSelector(selectAccount);

  const isAuthorizedAdmin = hasAnyAuthority(account.authorities, [
    AUTHORITIES.ADMIN,
  ]);

  const { from } = location.state || {
    from: {
      pathname: `${isAuthorizedAdmin ? "/admin/" : "/app"}`,
      search: location.search,
    },
  };

  useEffect(() => {
    if (!isAuthenticated) {
      console.debug(pageLocation.state);

      localStorage.setItem(REDIRECT_URL, pageLocation.state?.from?.pathname);
      window.location.href = getLoginUrl();
    }
  });

  if (isAuthenticated) {
    return <Navigate to={from} replace />;
  }

  return null;
};

export default LoginRedirect;
