import styles from "./header.module.scss";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import MenuToggle from "./menu-toggle";
import ThemeToggle from "../../components/theme-toggle/theme-toggle";
import AppButton from "../../elements/app-button/app-button";
import { useAppSelector } from "../../../state/store";

const navLinks: {
  path: string;
  text: string;
}[] = [
  {
    path: "/practice",
    text: "Practice",
  },
  {
    path: "/#contact",
    text: "Contact Us",
  },
];

const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [active, setActive] = React.useState<string>(location.pathname);
  const [open, setOpen] = useState(false);

  const authState = useAppSelector((state) => state.auth);

  useEffect(() => {
    setActive(location.pathname);
  }, [location.pathname]);

  const openHandler = () => setOpen(true);

  return (
    <Row
      className={`justify-content-center align-items-center ${styles["navbar-container"]}`}
    >
      <Col className="col-12">
        <Navbar collapseOnSelect expand="lg">
          <Container>
            <NavLink to={"/"}>
              <img src={"/logo_dark.png"} alt="logo" height={40} />
            </NavLink>
            <MenuToggle onClick={openHandler} />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="me-auto gap-md-0"></Nav>
              <Nav className="d-lg-flex gap-md-0 gap-lg-4 d-none">
                {navLinks.map((link, index) => (
                  <NavLink
                    key={link.path}
                    to={link.path}
                    className={`text-decoration-none d-flex align-items-center fs-6 font-weight-600 ${
                      active === link.path
                        ? `${styles["nav-hover-selected"]}`
                        : `${styles["nav-hover"]}`
                    }`}
                  >
                    {link.text}
                  </NavLink>
                ))}
              </Nav>
              <div className="align-items-center px-4">
                <ThemeToggle />
              </div>
              <div className={`${styles["app-button-container"]}`}>
                {authState.isAuthenticated ? (
                  <div className={`${styles["app-button-container"]}`}>
                    <AppButton
                      text="HOME"
                      btnSize="sm"
                      onClick={() => navigate("/app/home")}
                    />
                  </div>
                ) : (
                  <div className={`${styles["app-button-container"]}`}>
                    <AppButton
                      text="LOGIN"
                      btnSize="sm"
                      onClick={() => navigate("/login")}
                    />
                  </div>
                )}
              </div>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </Col>
    </Row>
  );
};

export default Header;
