import React from "react";
import PBar  from "react-bootstrap/ProgressBar";
import styles from "./style.module.scss";
import { Col, Row } from "react-bootstrap";

interface ProgressBarProps {
  now: number;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ now }) => {
  return (
    <div className={`${styles.progress} mb-2`}>
      <Row className="align-items-center">
        <Col className="col-8 col-md-9 col-lg-10 col-xl-11">
          <PBar
            variant="success"
            className={`${styles.progress__bar}`}
            now={now}
          />
        </Col>
        <Col
          className={`${styles.progress__text} col-4 col-md-3 col-lg-2 col-xl-1 d-flex justify-content-end`}
        >
          {`${now}%`} Complete
        </Col>
      </Row>
    </div>
  );
};

export default ProgressBar;
