import React from "react";
import { Col, Row } from "react-bootstrap";
import AppButton from "../../../shared/elements/app-button/app-button";
import styles from "./practice-page.module.scss";
import { useNavigate } from "react-router-dom";
import { useGetAllCoursesQuery } from "../../../state/server/courses/course-api";

interface PracticeCardProps {
  id: number;
  name : string;
  description: string;
  coverImgUrl: string;
}

const PracticeCard: React.FC<PracticeCardProps> = (props: PracticeCardProps) => {
  const navigate = useNavigate();

  const onViewClick = () => {
    navigate(props.id + "/tasks");
  };

  return (
    <div className={`${styles.cards} mb-3 px-4 py-2`}>
      <Row className="align-items-center">
        <Col className="col-12 col-lg-10">
          <Row>
            <div className="d-flex gap-2">

                <img src={props.coverImgUrl} className="my-auto" style={{width: "3rem", height: "3rem"}} />
       
              <p className={`${styles.cards__title}`}>{props.name}</p>
            </div>
            <p className={`${styles.cards__des} mt-3`}>{ props.description}</p>
          </Row>
        </Col>
        <Col className="col-12 col-lg-2 d-flex justify-content-end">
          <AppButton
            className={`${styles.cards__btn}`}
            text="View"
            btnType="filled"
            btnSize="md"
            onClick={onViewClick}
          />
        </Col>
      </Row>
    </div>
  );
};

const PracticePage: React.FC = () => {
  const { data, isLoading, isFetching, isError } = useGetAllCoursesQuery();

  return (
    <div>
      <section className="mt-4 mx-4">
        <Row>
          <Col xs={12}>
            <h5 className={`${styles.practice} mb-5 mb-lg-5 mt-5 mt-lg-3`}>
              Practice
            </h5>
          </Col>
        </Row>

        {data?.content.map((card) => (
          <PracticeCard key={card.id} {...card} />
        ))}
      </section>
    </div>
  );
};

export default PracticePage;
