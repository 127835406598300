import { rootApi } from "../api-manager";

interface Skeleton {
  chaptorId: number;
  skeletons: string;
}

export const skeletonAPI = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllSkeletons: builder.query<Skeleton[], void>({
      query: () => "skeletons",
    }),

    getSkeletonById: builder.query<Skeleton, number>({
      query: (chaptorId) => `skeletons/${chaptorId}`,
    }),

    createSkeleton: builder.mutation<Skeleton, Partial<Skeleton>>({
      query: (newSkeleton) => ({
        url: "skeletons",
        method: "GET",
        body: newSkeleton,
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetAllSkeletonsQuery,
  useGetSkeletonByIdQuery,
  useCreateSkeletonMutation,
} = skeletonAPI;
