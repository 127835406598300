import React, { useEffect } from "react";
import { IconType } from "react-icons";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "./app-primary-nav-bar-menu-item.module.scss";

interface IProps {
  link: string;
  icon?: IconType;
  title: string;
  userProfileImage?: string;
  isMobileNav?: boolean;
  isNormalIcon?: boolean;
}

const PrimaryNavBarMenuItem = (props: IProps) => {
  const { icon: IconComponent } = props;
  const location = useLocation();
  const navigate = useNavigate();

  const [active, setActive] = React.useState<boolean>(false);

  useEffect(() => {
    const isActiveItem = location.pathname.includes(props.link);

    setActive(isActiveItem);
  }, [location.pathname, props.link]);

  const handleNavigate = () => {
    if (props.link !== "/notices") {
      navigate(props.link);
    }
  };

  return (
    <div
      className={`mb-3 cursor-pointer d-flex ${
        props.isMobileNav ? "" : "flex-column"
      } align-items-center`}
      onClick={handleNavigate}
      onKeyDown={handleNavigate}
      style={{
        cursor: `${props.link === "/notices" ? "not-allowed" : ""}`,
        marginLeft: `${props.isMobileNav ? "100px" : ""}`,
      }}
    >
      <div
        className={`${props.isNormalIcon ? "" : styles.icon} ${
          active &&
          `${props.isMobileNav ? styles.mobileNavActive : styles.active}`
        } d-flex align-items-center justify-content-center`}
      >
        {IconComponent ? (
          <IconComponent
            size={20}
            color={
              active
                ? props.isMobileNav
                  ? "var(--accent-color)"
                  : "#ffffff"
                : props.isNormalIcon
                ? "#ffffff"
                : "var(--icon-color)"
            }
          />
        ) : (
          <img
            src={props.userProfileImage}
            alt="Profile"
            className={`${styles.profileImage}`}
          />
        )}
      </div>
      <span
        className={`${
          props.isMobileNav ? styles.mobileNavTitle : styles.title
        }`}
      >
        {props.isMobileNav ? (
          <p>{props.title}</p>
        ) : (
          props.title
            .split("\n")
            .map((line, index) => <p key={index}>{line}</p>)
        )}
      </span>
    </div>
  );
};

export default PrimaryNavBarMenuItem;
