import { setAccount, setLogoutUrl } from "../../local/auth/auth-slice";
import { rootApi } from "../api-manager";
import LogoutResponse from "./logout-response-dto";
import LoginResponseDTO from "./user-response-dto";

export const authAPI = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    account: builder.query<LoginResponseDTO, void>({
      query() {
        return {
          url: "account",
          method: "GET",
        };
      },
      async onQueryStarted(_args, { dispatch, queryFulfilled }) {
        try {
          const res = await queryFulfilled;
          dispatch(setAccount(res.data));
        } catch (error) {
          console.log(error);
        }
      },
    }),

    logout: builder.mutation<LogoutResponse, void>({
      query() {
        return {
          url: "logout",
          method: "POST",
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const res = await queryFulfilled;
          dispatch(setLogoutUrl(res.data.logoutUrl));
        } catch (error) {
          console.log(error);
        }
      },
    }),
  }),
  overrideExisting: false,
});

export const { useAccountQuery, useLogoutMutation } = authAPI;
