import React from "react";
import { Accordion, Col } from "react-bootstrap";
import AppButton from "../../../shared/elements/app-button/app-button";
import styles from "../practice/practice-page.module.scss";
import { useNavigate } from "react-router-dom";
import { BsPen, BsPlay } from "react-icons/bs";

export interface AccordionItemProps {
  id: number;
  name: string;
  description: string;
  lessonMode: "ANY" | "EXERCISE";
}

const AccordionItem: React.FC<AccordionItemProps> = (props: AccordionItemProps) => {
  const navigate = useNavigate();
  
  const onPracticeButtonClick = () =>{
    navigate(props.id +  "/exercises");
  };

  return (
    <div className={`${styles.accordion}`}>
      <Accordion.Item eventKey={props.id.toString()} className="mb-5">
        <Accordion.Header className={`${styles.accordion__header}`}>
          <div>
          {props.lessonMode === "ANY"  ?   <BsPlay size={24}  /> : <BsPen /> }
          </div>
          <div className={`${styles.accordion__title} px-2`}>{props.name}</div>
        </Accordion.Header>
        <Accordion.Body className={`${styles.accordion__content}`}>
          <p className="py-3">{props.description}</p>

          {props.lessonMode === "ANY" && (
            <Col className="col-12 col-lg-2 d-flex justify-content-start">
              <AppButton
                className={`${styles.cards__btn}`}
                text="Practice"
                btnType="filled"
                btnSize="md"
                onClick={onPracticeButtonClick}
              />
            </Col>
          )}

          {props.lessonMode === "EXERCISE" && (
            <Col className="col-12 col-lg-2 d-flex justify-content-start">
              <AppButton
                className={`${styles.cards__btn}`}
                text="Practice"
                btnType="filled"
                btnSize="md"
                onClick={onPracticeButtonClick}
              />
            </Col>
          )}
        </Accordion.Body>
      </Accordion.Item>
    </div>
  );
};

export default AccordionItem;
