import React from "react";
import styles from "./app-buttonIcon.module.scss";

interface AppButtonIconProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  icon: React.ReactNode;
  text: string;
  onClick?: () => void;
  style?: React.CSSProperties;
}

const AppButtonIcon: React.FC<AppButtonIconProps> = ({
  icon,
  text,
  onClick,
  style,
}) => {
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <button
      className="d-flex gap-2"
      onClick={handleClick}
      style={{
        border: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        ...style,
      }}
    >
      <div className={styles.buttonIcon__icon}>
        <div>{icon}</div>
      </div>
      <div>
        <div className={`${styles.buttonIcon__text}`}>{text}</div>
      </div>
    </button>
  );
};

export default AppButtonIcon;
