import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import LandingLayout from "../shared/layout/layouts/landing-layout";
import LandingPage from "./landing/landing-page";
import PageNotFound from "../shared/error/page-not-found";
import LoginRedirect from "./login/login-redirect";
import MainLayout from "../shared/layout/layouts/main-layout";
import HomePage from "./app/home/home-page";
import Logout from "./login/logout";
import PrivateRoute from "../shared/components/auth/private-route";
import { AUTHORITIES } from "../config/constants";
import PracticePage from "./app/practice/practice-page";
import TasksPage from "./app/tasks/tasks-page";
import SampleWriting from "./app/writing/sampleWriting-page";
import WritingPart1 from "./app/writing/part1/writingPart1";
import SecondaryLayout from "../shared/layout/layouts/secondary-layout";
import PracticePublicPage from "./practicePublic/practicePublic-page";
import ProfilePage from "./app/profile/profile-page";

const AppRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LandingLayout />}>
          <Route index element={<LandingPage />} />
          <Route path="practice" element={<PracticePublicPage />} />
          <Route path="*" element={<PageNotFound />} />
        </Route>
        <Route path="login" element={<LoginRedirect />} />
        <Route path="logout" element={<Logout />} />
        <Route path="*" element={<PageNotFound />} />
        <Route
          path="/app"
          element={<PrivateRoute hasAnyAuthorities={[AUTHORITIES.USER]} />}
        >
          <Route element={<MainLayout />}>
            <Route index element={<Navigate to="practice" />} />
            <Route path="home" element={<HomePage />} />
            <Route path="student/account/profile" element={<ProfilePage />} />
            <Route path="home/profile" element={<ProfilePage />} />
            <Route path="practice" element={<PracticePage />} />
            <Route
              path="practice/:courseId"
              element={<SecondaryLayout title="Practice" />}
            >
              <Route path="tasks" element={<TasksPage />} />
              <Route path="tasks/:taskId/exercises" element={<SampleWriting />} />
              <Route path="tasks/:taskId/exercises/part1" element={<WritingPart1 />} />
            </Route>

            <Route path="*" element={<PageNotFound />} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
