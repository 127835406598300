import { rootApi } from "../api-manager";
import LessonListResponse from "./lesson-list-response-dto";

export const lessonAPI = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllLessons: builder.query<LessonListResponse, number>({
      query(courseId) {
        return {
          url: `courses/${courseId}/lessons/all`,
          method: "GET",
          params: {sort : "id"}
        };
      }
    })
  }),
  overrideExisting: false,
});

export const { useGetAllLessonsQuery } = lessonAPI;
