import { Col, Row } from "react-bootstrap";
import styles from "./profile-page.module.scss";
import { BsList, BsPencilSquare } from "react-icons/bs";
import { FaCheckCircle } from "react-icons/fa";

const ProfilePage = () => {
  return (
    <div>
      <section className="mx-5">
        <div
          className={`${styles.profile__txtOliver} d-flex gap-2 align-items-center my-5`}
        >
          <div>
            <BsList size={40} color="red" />
          </div>
          <div>My Profile</div>
        </div>
        <div className="d-flex align-items-center gap-3">
          <div style={{ position: "relative", display: "inline-block" }}>
            <img
              src={"/profilePic.png"}
              alt="An profile picture"
              style={{ maxWidth: "100%", height: "auto", borderRadius: "50%" }}
            />
            <div
              style={{
                position: "absolute",
                padding: "5px",
                top: "100%",
                left: "50%",
                borderRadius: "50%",
                transform: "translate(-50%, -50%)",
                color: "white",
                background: "red",
              }}
            >
              <BsPencilSquare size={16} />
            </div>
          </div>
          <div>
            <div className={`${styles.profile__name}`}>Oliver Queen</div>
            <div className={`${styles.profile__index}`}>SKF2300005</div>
            <div className={`${styles.profile__index}`}>Student</div>
          </div>
        </div>

        <Row className="mt-5 ">
          <Col className="col-12 col-md-6 mb-3">
            <div className={`${styles.profile__details}  p-4`}>
              <div className={`${styles.profile__txtName}`}>First Name</div>
              <div className={`${styles.profile__txtOliver} mb-4`}>Oliver</div>

              <div className={`${styles.profile__txtName} `}>Last Name</div>
              <div className={`${styles.profile__txtOliver} mb-4 `}>Queen</div>

              <div className={`${styles.profile__txtName}  `}>Bio</div>
              <div className={`${styles.profile__txtBioDes} mb-4`}>
                Graphics Processing Units, or GPUs for short, have been
                essential in enabling rich rendering and computational
                applications in personal computing. WebGPU is an API that
                exposes the capabilities of GPU hardware for the Web. The API is
                designed from the ground up to
              </div>

              <div
                style={{
                  color: "var(--primary, #FF495D)",
                  fontSize: "14px",
                  fontWeight: 500,
                }}
              >
                Edit profile
              </div>
            </div>
          </Col>

          <Col className="col-12 col-md-6 ">
            <Row className={`${styles.profile__row} m-0`}>
              <div
                className={`${styles.profile__emailCard} d-flex justify-content-between align-items-center p-4 mb-4`}
              >
                <div className="justify-content-center">
                  <div>Email</div>
                  <div className="d-flex gap-2">
                    <div>oliverqueen@mail.com</div>
                    <div>
                      <FaCheckCircle size={18} color="var(--accent-color)" />
                    </div>
                  </div>
                </div>

                <div>
                  <div>
                    <BsPencilSquare size={18} />
                  </div>
                </div>
              </div>
            </Row>

            <Row className={`${styles.profile__row} m-0`}>
              <div
                className={`${styles.profile__emailCard} d-flex justify-content-between align-items-center p-4 mb-4`}
              >
                <div className="justify-content-center">
                  <div>Mobile Number</div>
                  <div className="d-flex gap-2">
                    <div>+94 75 154 1247</div>
                    <div>
                      <FaCheckCircle size={18} color="var(--accent-color)" />
                    </div>
                  </div>
                </div>

                <div>
                  <div>
                    <BsPencilSquare size={18} />
                  </div>
                </div>
              </div>
            </Row>

            <Row className={`${styles.profile__row} m-0`}>
              <div
                className={`${styles.profile__emailCard} d-flex justify-content-between align-items-center p-4 mb-4`}
              >
                <div className="justify-content-center">
                  <div>Password</div>
                  <div className="d-flex gap-2">
                    <div>*******</div>
                  </div>
                </div>

                <div>
                  <div>
                    <BsPencilSquare size={18} />
                  </div>
                </div>
              </div>
            </Row>
          </Col>
        </Row>
      </section>
    </div>
  );
};

export default ProfilePage;
