import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { DeepMap, FieldError, FieldValues, UseFormRegisterReturn } from 'react-hook-form';
import { IconType } from 'react-icons';
import styles from './app-input.module.scss';
import AppToggle from '../app-toggle/app-toggle';

export type FieldErrors<TFieldValues extends FieldValues = FieldValues> = DeepMap<TFieldValues, FieldError>;

interface IProps extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  errorMessage?: string;
  icon?: IconType;
  label?: string;
  onIconClick?: () => void;
  register?: UseFormRegisterReturn;
  placeholder: string;
  errors?: FieldErrors;
  isError?: boolean;
  isSuccess?: boolean;
  isRequired?: boolean;
  isToggle?: boolean;
  disabled?: boolean;
  isClicked?: boolean;
  button?: JSX.Element;
}

const AppInput = (props: IProps) => {
  const {
    className,
    icon: IconComponent,
    label,
    onIconClick,
    register,
    placeholder,
    errors,
    isError,
    isSuccess,
    isRequired,
    value,
    defaultValue,
    isToggle,
    disabled,
    isClicked,
    button,
    ...rest
  } = props;

  const [toggleState, setToggleState] = useState<boolean>(isToggle ? !!isClicked  : true);

  const handleToggleChange = (newState: any) => {
    setToggleState(newState);
    rest.onChange?.(newState);
  };

  return (
    <Row>
      <Col>
        <div className={`${className}`}>
          {label && (
            <label className={`${styles.label} d-flex pb-1`} htmlFor="flexSwitchCheckDefault">
              {label}
              {isRequired && <span className={`${styles.required} ms-1`}> *</span>}
              {isToggle && (
                <span className={`ms-2 pb-2 d-flex align-items-center`}>
                  <AppToggle initialState={toggleState} onToggle={handleToggleChange} />
                </span>
              )}
            </label>
          )}
          {toggleState && (
            <div
              className={`appInput ${(rest.name && errors && errors[rest.name]?.message) || isError ? `hasDanger` : isSuccess ? `hasSuccess` : ``}`}
            >
              {rest.type === 'number' ? (
                <input
                  {...register}
                  placeholder={placeholder}
                  type={rest.type}
                  min="0"
                  onKeyDown={e => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()}
                />
              ) : (
                <input {...register} placeholder={placeholder} type={rest.type} disabled={disabled} defaultValue={value || ''} />
              )}

              {IconComponent && (
                <div className={`icon`} onClick={onIconClick}>
                  <IconComponent size={24}  />
                </div>
              )}
              {button && <div>{button}</div>}
            </div>
          )}
          {rest.id && <span className={`${styles.errorMessage}`}>{errors && errors[rest.id]?.message}</span>}
        </div>
      </Col>
    </Row>
  );
};

export default AppInput;
