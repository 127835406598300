import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { IListItem } from '../app-secondary-nav-bar';
import styles from './app-menu-content.module.scss';

interface IProps {
  item: IListItem;
  basePath?: string;
}

const AppMenuContent = (props: IProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeItem, setActiveItem] = React.useState<boolean>(false);

  React.useEffect(() => {
    let isActiveItem = false;
    const pathnameArray = location.pathname.split('/');
    const filteredPathArray = pathnameArray.filter(value => value !== '');
    isActiveItem = filteredPathArray.includes(props.item.path ?? '');
    setActiveItem(isActiveItem);
  }, [location.pathname]);

  const onClickItem = (path?: string) => {
    if (path) {
      if (props.basePath) {
        navigate(`${props.basePath}/${path}`);
      } else {
        navigate(path);
      }
    }
  };

  return (
    <Row className="cursor-pointer" style={{ cursor: `${!props.item.path ? 'not-allowed' : ''}` }}>
        <Col
          className={`${styles.menuItem} ${activeItem ? styles.activeItem : ''} p-4`}
          key={props.item.id}
          onClick={() => onClickItem(props.item.path)}
        >
          {props.item.itemName}
        </Col>
      </Row>
  );
};

export default AppMenuContent;
