import { useEffect } from "react";
import AppRoutes from "./pages/routes";
import { ETheme } from "./shared/enums/theme";
import { changeTheme } from "./state/local/app/app-slice";
import { useAppDispatch, useAppSelector } from "./state/store";
import { authAPI } from "./state/server/auth/auth-api";
import { selectIsAuthenticated } from "./state/local/auth/auth-slice";

function App() {
  const dispatch = useAppDispatch();
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  
  useEffect(() => {
    const appTheme = localStorage.getItem("theme") ?? ETheme.LIGHT;
    dispatch(changeTheme(appTheme));
    document.documentElement.setAttribute("data-theme", appTheme);
  }, [dispatch]);

  useEffect(() => {
    if (!isAuthenticated) {
      dispatch(authAPI.endpoints.account.initiate());
    }
  }, [dispatch, isAuthenticated]);

  return (
    <div className="App">
      <header className="App-header">
        <AppRoutes />
      </header>
    </div>
  );
}

export default App;
