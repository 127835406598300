import styles from './theme-toggle.module.scss';
import { useAppDispatch, useAppSelector } from '../../../state/store';
import { changeTheme, selectTheme } from '../../../state/local/app/app-slice';
import { ETheme } from '../../enums/theme';
import { BsMoon, BsSun } from 'react-icons/bs';

interface IProps {
  iconColor?: boolean;
}

const ThemeToggle = (props: IProps) => {
  const dispatch = useAppDispatch();
  const  theme  = useAppSelector(selectTheme);

  const handleThemeChange = () => {
    const newTheme: ETheme = theme === ETheme.LIGHT ? ETheme.DARK : ETheme.LIGHT;
    dispatch(changeTheme(newTheme));
    document.documentElement.setAttribute('data-theme', newTheme);
    localStorage.setItem('theme', newTheme);
  };

  return (
    <div>
      <button onClick={handleThemeChange} className={`${props.iconColor ? styles.iconColor : styles.toggle} d-flex align-items-center`}>
        {theme === ETheme.DARK ? < BsSun />  :  < BsMoon  />}
      </button>
    </div>
  );
};

export default ThemeToggle;
