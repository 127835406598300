import { rootApi } from '../api-manager';

interface Chaptor {
  id: number;
  title: string;
  imageURL: string;
  instructions: string;
  question: string;
  durationInMin: number;
}

export const chaptorAPI = rootApi.injectEndpoints({
  
  
  endpoints: (builder) => ({
    getChaptors: builder.query<Chaptor[], void>({
      query: () => 'tests',
    }),
    saveChaptor: builder.mutation<Chaptor, Partial<Chaptor>>({
      query: (newChaptor) => ({
        url: 'tests',
        method: 'GET',
        body: newChaptor,
      }),
    }),
  }),
  overrideExisting: false,
});

export const { useGetChaptorsQuery, useSaveChaptorMutation } = chaptorAPI;