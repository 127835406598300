import { useState } from "react";
import { Col, Container, Navbar, Row } from "react-bootstrap";
import { NavLink, Outlet } from "react-router-dom";
import MenuToggle from "../header/menu-toggle";
import { useAppSelector } from "../../../state/store";
import AppPrimaryNavBar from "../../components/nav-bar/app-primary-nav-bar/app-primary-nav-bar";
import ScrollToTop from "../../components/scroll-to-top/scroll-to-top";
import styles from "../header/header.module.scss";
import AppPrimaryMobileNavBar from "../../components/nav-bar/app-primary-mobile-nav-bar/app-primary-mobile-nav-bar";
import OffCanvas from "../../components/off-canvas/off-canvas";

const MainLayout = () => {
  const authState = useAppSelector((state) => state.auth);

  const [open, setOpen] = useState(false);

  const openHandler = () => setOpen(true);
  const closeHandler = () => setOpen(false);

  return (
    <Container fluid className={`main-layout px-0 overflow-hidden`}>
      <nav className="main-layout__nav d-none d-md-flex">
        <AppPrimaryNavBar
          imagePath={authState.account.imageUrl}
          userFirstName={authState.account.firstName}
          userLastName={authState.account.lastName}
        />
      </nav>
      <section className="main-layout__body">
        <ScrollToTop />

        <div className={`d-flex d-md-none ${styles["navbar-container"]}`}>
          <Navbar className="w-100">
            <Container fluid className="w-100 p-0 justify-content-center">
              <Row className="justify-content-between w-100">
                <Col>
                  <NavLink to={"/"}>
                    <img
                      src={"/logo-min.png"}
                      alt="skill_surf logo"
                      height={40}
                    />
                  </NavLink>
                </Col>
                <Col className="col-auto">
                  <MenuToggle onClick={openHandler} />
                </Col>
              </Row>
            </Container>
          </Navbar>
        </div>

        <div className="main-layout__content">
          <Outlet />
        </div>
      </section>

      <header>
        <OffCanvas open={open} closeHandler={closeHandler} placement="end">
          <AppPrimaryMobileNavBar closeHandler={closeHandler} isInMyCourses />
        </OffCanvas>
      </header>
    </Container>
  );
};

export default MainLayout;
