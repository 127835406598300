import { Modal } from "react-bootstrap";
import styles from "./writingPart1-page.module.scss";

const AnswerPopup: React.FC<{
  show: boolean;
  onHide: () => void;
}> = ({ show, onHide }) => {
  return (
    <div
      className="modal show"
      style={{ display: "block", position: "initial" }}
    >
      <Modal show={show} onHide={onHide} centered>
        <Modal.Header closeButton>
          <Modal.Title className={`${styles.popup__titleTxt}`}>
            Give an Answer
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className={`${styles.popup__bodyTxt}`}>
          <p>You have not typed any answer yet</p>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AnswerPopup;
