import { rootApi } from "../api-manager";
import CourseListResponse from "./course-list-response-dto";

export const courseAPI = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllCourses: builder.query<CourseListResponse, void>({
      query() {
        return {
          url: "courses/all",
          method: "GET",
          params: {sort : "id"}
        };
      }
    })
  }),
  overrideExisting: false,
});

export const { useGetAllCoursesQuery } = courseAPI;
