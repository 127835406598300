import { BsList, BsPen } from "react-icons/bs";
import { IoTime } from "react-icons/io5";
import { FaRegCircleCheck } from "react-icons/fa6";
import styles from "./sampleWriting-page.module.scss";
import { Container } from "react-bootstrap";
import AppButton from "../../../shared/elements/app-button/app-button";
import { useGetChaptorsQuery } from "../../../state/server/sampleWriting/api";
import { useNavigate } from "react-router-dom";

const SampleWriting = () => {
  const navigate = useNavigate();
  const { data: chaptors, isLoading } = useGetChaptorsQuery();

  if (isLoading) {
    return <div>Loading...</div>;
  }
  
  const onStartButtonClick = () =>{
    navigate("part1");
  };

  return (
    <div>
      <section>
        <div className="d-flex align-items-center p-4">
          <div>
            <BsList size={40} color="var(--accent-color)" />
          </div>

          <p
            style={{
              color: "var(--text-primary-color)",
              fontWeight: "600",
              fontSize: "16px",
            }}
            className="mt-3 mx-2"
          >
            Sample Writing Practice
          </p>
        </div>
      </section>

      <section className={`${styles.card} py-5 mx-4`}>
        <Container className="px-4 mx-3">
          {chaptors &&
            chaptors.map((chaptor) => (
              <div className="d-flex align-items-center">
                <div className="d-flex gap-2 ">
                  <div className={`${styles.card__icon} `}>
                    <BsPen size={20} color="var(--text-primary-color)" />
                  </div>
                  <div className={`${styles.card__title} `}>
                    Sample practice {chaptor.id}
                  </div>
                </div>

                <div
                  className={`${styles.card__timeIcon} d-flex gap-1 ms-auto align-items-center justify-content-center`}
                >
                  <div>
                    <IoTime size={24} color="var(--text-secondary-color)" />
                  </div>
                  <div className={`${styles.card__duration} `}>Duration :</div>
                  <div className={`${styles.card__minutes} `}>
                    {chaptor.durationInMin} minutes
                  </div>
                </div>
              </div>
            ))}
          <div className="mt-5">
            <h5 className={`${styles.card__pleaseTxt}`}>
              Please carefully read the following instructions before starting
              the practice test.
            </h5>
            <h3 className={`${styles.card__instructionTxt} my-4`}>
              Instructions to Candidates
            </h3>

            <div className="d-flex gap-2 align-items-center">
              <div>
                <FaRegCircleCheck size={16} color="var(--accent-color)" />
              </div>
              <div className={`${styles.card__instructions} pt-1`}>
                You have a total of 20 minutes to complete the tasks. Allocate
                your time efficiently.
              </div>
            </div>

            <div className="d-flex gap-2 align-items-center">
              <div>
                <FaRegCircleCheck size={16} color="var(--accent-color)" />
              </div>
              <div className={`${styles.card__instructions} pt-1`}>
                Adhere to the specified word counts – at least 150 words.
              </div>
            </div>

            <div className="d-flex gap-2 align-items-center">
              <div>
                <FaRegCircleCheck size={16} color="var(--accent-color)" />
              </div>
              <div className={`${styles.card__instructions} pt-1`}>
                Organize your response with an introduction, body paragraphs,
                and a conclusion, maintaining coherence throughout.
              </div>
            </div>

            <div className="d-flex gap-2 align-items-center">
              <div>
                <FaRegCircleCheck size={16} color="var(--accent-color)" />
              </div>
              <div className={`${styles.card__instructions} pt-1`}>
                Prioritize clarity, coherence, and language accuracy during the
                review process before submitting your responses.
              </div>
            </div>

            <div className="d-flex gap-2 align-items-center">
              <div>
                <FaRegCircleCheck size={16} color="var(--accent-color)" />
              </div>
              <div className={`${styles.card__instructions} pt-1`}>
                If you encounter any technical issues, feel free to contact our
                chat support for assistance.
              </div>
            </div>
            <h3 className={`${styles.card__goodTxt} pt-1 mt-4 mb-5`}>
              Good luck with your practice test!
            </h3>

            <AppButton
              className={`${styles.card__btn}`}
              text="Start Test"
              btnType="filled"
              btnSize="md"
              onClick={onStartButtonClick}
            />
          </div>
        </Container>
      </section>
    </div>
  );
};

export default SampleWriting;
